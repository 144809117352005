<template>
  <p style="margin:15px 0;text-align:center;color:#999;">
    <small>本系统由 蜂动科技 提供</small>
  </p>
</template>

<script>
import { toRefs, reactive, onMounted } from 'vue'

export default {
  setup () {
    const state = reactive({
    })
    const init = () => {
    }
    onMounted(() => {
      init()
    })
    return {
      ...toRefs(state)
    }
  }
}
</script>
